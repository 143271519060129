import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, getDefaultConfig } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { WalletProvider } from './WalletContext';

import KonkLand from './KonkLand';
import KonkWheel from './KonkWheel';
import KonkRun from './components/KonkRun';
import KonkHyperMining from './components/KonkHyperMining';
import KONKDrop from './components/KONKDrop';

const blastChain = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.blast.io'] },
    public: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'BlastScan', url: 'https://blastscan.io' },
  },
};

const config = getDefaultConfig({
  appName: 'KONK App',
  projectId: '812fb5d36869c7b001c1237443d07b07',
  chains: [blastChain, mainnet],
  ssr: true,
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <WalletProvider>
            <Router>
              <Routes>
                <Route path="/" element={<KonkLand />} />
                <Route path="/konk-wheel" element={<KonkWheel />} />
                <Route path="/konk-run" element={<KonkRun />} />
                <Route path="/konk-hyper-mining" element={<KonkHyperMining />} />
                <Route path="/konk-drop" element={<KONKDrop />} />
              </Routes>
            </Router>
          </WalletProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;