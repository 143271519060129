import React, { useState, useCallback, useEffect } from 'react';
import { useWallet } from '../WalletContext';
import { parseEther, formatEther } from 'viem';
import { useReadContract, useWriteContract, usePublicClient } from 'wagmi';
import { Upload, Home, FileText } from 'lucide-react'; // Add Home and FileText icons
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Link } from 'react-router-dom'; // Make sure this is imported
import './KONKDrop.css';

// Constants
const KONK_ADDRESS = '0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF';
const STKONK_ADDRESS = '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2';
const KONK_DROP_ADDRESS = '0xea3e4956da53ee62357bc09c9aedf12435ca2352';
const REQUIRED_AMOUNT = parseEther('10000');

// ABI for the contract
const KONK_DROP_ABI = [
    // ETH Disperse
    {
        "inputs": [
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_amounts", "type": "uint256[]"}
        ],
        "name": "disperseETH",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    // ERC20 Disperse
    {
        "inputs": [
            {"internalType": "address", "name": "_token", "type": "address"},
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_amounts", "type": "uint256[]"}
        ],
        "name": "disperseERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    // ERC721 Disperse
    {
        "inputs": [
            {"internalType": "address", "name": "_token", "type": "address"},
            {"internalType": "address[]", "name": "_recipients", "type": "address[]"},
            {"internalType": "uint256[]", "name": "_tokenIds", "type": "uint256[]"}
        ],
        "name": "disperseERC721",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    // Balance check
    {
        "inputs": [{"internalType": "address", "name": "_user", "type": "address"}],
        "name": "isEligible",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    },
    // Get Stats
    // Get Stats
    {
      "inputs": [],
      "name": "getStats",
      "outputs": [
          {"internalType": "uint256", "name": "totalDrops", "type": "uint256"},
          {"internalType": "uint256", "name": "totalTokensDropped", "type": "uint256"},
          {"internalType": "uint256", "name": "totalETHDropped", "type": "uint256"},
          {"internalType": "uint256", "name": "totalNFTsDropped", "type": "uint256"},
          {"internalType": "uint256", "name": "totalRecipients", "type": "uint256"}
      ],
      "stateMutability": "view",
      "type": "function"
    }
];

// ERC20 ABI for balance checks
const ERC20_ABI = [
    {
        "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "spender", "type": "address"},
            {"internalType": "uint256", "name": "amount", "type": "uint256"}
        ],
        "name": "approve",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "owner", "type": "address"},
            {"internalType": "address", "name": "spender", "type": "address"}
        ],
        "name": "allowance",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    }
];

const ERC721_ABI = [
    {
        "inputs": [
            {"internalType": "address", "name": "operator", "type": "address"},
            {"internalType": "bool", "name": "approved", "type": "bool"}
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "address", "name": "owner", "type": "address"},
            {"internalType": "address", "name": "operator", "type": "address"}
        ],
        "name": "isApprovedForAll",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "stateMutability": "view",
        "type": "function"
    }
];

const EligibilityPopup = ({ onClose }) => (
    <div className="eligibility-modal">
        <div className="modal-content">
            <button onClick={onClose} className="close-button">
                ×
            </button>
            <h3>Need More KONK!</h3>
            <p>You need TO HODL/STAKE at least 10,000 KONK tokens to use KONKDROP.</p>
            <a 
                href="https://app.thruster.finance/?token2=0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-button"
            >
                BUY KONK
            </a>
        </div>
    </div>
);

const KONKDrop = () => {
    const { walletAddress, isConnected } = useWallet();
    const publicClient = usePublicClient();
    const [mode, setMode] = useState('eth');
    const [showPopup, setShowPopup] = useState(false);
    const [walletInput, setWalletInput] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [stats, setStats] = useState({
      totalDrops: '0',
      totalTokensDropped: '0',
      totalETHDropped: '0',
      totalNFTsDropped: '0',
      totalRecipients: '0'
  });
    const [inputSummary, setInputSummary] = useState({ totalAmount: '0', totalWallets: 0 });
    const [isUserEligible, setIsUserEligible] = useState(false);

    const checkEligibility = useCallback(async () => {
        if (!isConnected || !walletAddress || !publicClient) return;

        try {
            const [konkBal, stkonkBal] = await Promise.all([
                publicClient.readContract({
                    address: KONK_ADDRESS,
                    abi: ERC20_ABI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                }),
                publicClient.readContract({
                    address: STKONK_ADDRESS,
                    abi: ERC20_ABI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                })
            ]);

            const totalBalance = Number(formatEther(konkBal)) + Number(formatEther(stkonkBal));
            setIsUserEligible(totalBalance >= 10000);
        } catch (err) {
            console.error('Error checking eligibility:', err);
            setIsUserEligible(false);
        }
    }, [isConnected, walletAddress, publicClient]);

    useEffect(() => {
        checkEligibility();
    }, [checkEligibility]);

    const updateStats = useCallback(async () => {
      if (!publicClient || !KONK_DROP_ADDRESS) return;
  
      try {
          const result = await publicClient.readContract({
              address: KONK_DROP_ADDRESS,
              abi: KONK_DROP_ABI,
              functionName: 'getStats'
          });
  
          setStats({
              totalDrops: result[0].toString(),
              totalTokensDropped: result[1].toString(),
              totalETHDropped: result[2].toString(),
              totalNFTsDropped: result[3].toString(),
              totalRecipients: result[4].toString()
          });
      } catch (error) {
          console.error('Error fetching stats:', error);
      }
  }, [publicClient]);

    useEffect(() => {
        if (isConnected) {
            updateStats();
            const interval = setInterval(updateStats, 30000);
            return () => clearInterval(interval);
        }
    }, [updateStats, isConnected]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const text = await file.text();
            handleInputChange({ target: { value: text } });
        } catch (err) {
            setError('Error reading file');
        }
    };

    const handleInputChange = (e) => {
      const newInput = e.target.value;
      setWalletInput(newInput);
      
      try {
          const entries = newInput
              .split('\n')
              .map(line => line.trim())
              .filter(line => line)
              .map(line => {
                  const [address, amount] = line.split(/[\s,]+/);
                  return { address: address?.trim(), amount: amount?.trim() };
              })
              .filter(({ address, amount }) => address && amount);
  
          if (mode === 'erc721') {
              setInputSummary({
                  totalAmount: entries.length.toString(), // Number of NFTs is same as number of entries
                  totalWallets: entries.length,
                  isNFT: true
              });
          } else {
              const total = entries.reduce((sum, entry) => {
                  try {
                      return sum + parseFloat(entry.amount);
                  } catch (err) {
                      return sum;
                  }
              }, 0);
  
              setInputSummary({
                  totalAmount: total.toString(),
                  totalWallets: entries.length,
                  isNFT: false
              });
          }
      } catch (err) {
          setInputSummary({ totalAmount: '0', totalWallets: 0, isNFT: false });
      }
  };

    const parseInput = () => {
        return walletInput
            .split('\n')
            .map(line => line.trim())
            .filter(line => line)
            .map(line => {
                const [address, amount] = line.split(/[\s,]+/);
                return { address: address?.trim(), amount: amount?.trim() };
            })
            .filter(({ address, amount }) => address && amount);
    };

    const { writeContract } = useWriteContract();

    const handleAirdrop = async () => {
        if (!isUserEligible) {
            setShowPopup(true);
            return;
        }

        try {
            setLoading(true);
            setError('');

            const entries = parseInput();
            const addresses = entries.map(e => e.address);

            if (mode === 'eth') {
                const amounts = entries.map(e => parseEther(e.amount));
                const total = amounts.reduce((a, b) => a + b, 0n);

                // Check ETH balance
                const balance = await publicClient.getBalance({ address: walletAddress });
                if (balance < total) {
                    setError(`Insufficient ETH balance. You need ${formatEther(total)} ETH`);
                    return;
                }

                await writeContract({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseETH',
                    args: [addresses, amounts],
                    value: total
                });
            } else if (mode === 'erc20') {
                const amounts = entries.map(e => parseEther(e.amount));
                const total = amounts.reduce((a, b) => a + b, 0n);

                // Check token balance
                const tokenBalance = await publicClient.readContract({
                    address: tokenAddress,
                    abi: ERC20_ABI,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                });

                if (tokenBalance < total) {
                    setError(`Insufficient token balance. You need ${formatEther(total)} tokens`);
                    return;
                }

                // Check allowance
                const allowance = await publicClient.readContract({
                    address: tokenAddress,
                    abi: ERC20_ABI,
                    functionName: 'allowance',
                    args: [walletAddress, KONK_DROP_ADDRESS],
                });

                if (allowance < total) {
                    try {
                        await writeContract({
                            address: tokenAddress,
                            abi: ERC20_ABI,
                            functionName: 'approve',
                            args: [KONK_DROP_ADDRESS, total],
                        });
                        setError('Token approval sent! Click Airdrop again to complete the transfer.');
                        setLoading(false);
                        return;
                    } catch (approvalError) {
                        if (approvalError.code === 4001) {
                            setError('Token approval rejected. Please try again.');
                        } else {
                            setError(`Approval failed: ${approvalError.message}`);
                        }
                        return;
                    }
                }

                // Proceed with transfer
                await writeContract({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseERC20',
                    args: [tokenAddress, addresses, amounts]
                });
            } else if (mode === 'erc721') {
                const tokenIds = entries.map(e => Number(e.amount));

                // Check approval
                const isApproved = await publicClient.readContract({
                    address: tokenAddress,
                    abi: ERC721_ABI,
                    functionName: 'isApprovedForAll',
                    args: [walletAddress, KONK_DROP_ADDRESS],
                });

                if (!isApproved) {
                    try {
                        await writeContract({
                            address: tokenAddress,
                            abi: ERC721_ABI,
                            functionName: 'setApprovalForAll',
                            args: [KONK_DROP_ADDRESS, true],
                        });
                        setError('NFT approval sent! Click Airdrop again to complete the transfer.');
                        setLoading(false);
                        return;
                    } catch (approvalError) {
                        if (approvalError.code === 4001) {
                            setError('NFT approval rejected. Please try again.');
                        } else {
                            setError(`NFT approval failed: ${approvalError.message}`);
                        }
                        return;
                    }
                }

                await writeContract({
                    address: KONK_DROP_ADDRESS,
                    abi: KONK_DROP_ABI,
                    functionName: 'disperseERC721',
                    args: [tokenAddress, addresses, tokenIds]
                });
            }

            setWalletInput('');
            setTokenAddress('');
            setInputSummary({ totalAmount: '0', totalWallets: 0 });
            updateStats();
        } catch (err) {
            console.error('Transaction error:', err);
            setError(err.message || 'Transaction failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="drop-container">
            <div className="header-section">
            <div className="header-content">
        <div className="header-left">
            <Link to="/" className="home-icon">
                <Home size={24} />
            </Link>
            <div className="logo-title">
                {/* <img 
                    src="/images/konkdropLogo.svg" 
                    alt="KONK DROP" 
                    className="header-logo"
                /> */}
                <div className="header-text">
                    <h1>KONK DROP</h1>
                    <p>Airdrop your tokens with extra KONKness! 🚀</p>
                </div>
            </div>
        </div>
        <div className="header-right">
            <ConnectButton />
            <a 
                href="https://kiyosonk.gitbook.io/konk-hypers-pool/smart-contract-details/contract-addresses" 
                target="_blank" 
                rel="noopener noreferrer"
                className="docs-icon"
            >
                <FileText size={24} />
            </a>
        </div>
    </div>
            </div>

            {isConnected ? (
                <>
                   <div className="stats-row">
                      <div className="stat-box" data-tooltip="Total number of successful airdrops">
                          <span className="stat-value">{Number(stats.totalDrops).toLocaleString()}</span>
                          <span className="stat-label">Total Drops</span>
                      </div>
                      <div className="stat-box" data-tooltip="Total ETH distributed">
                          <span className="stat-value">
                              {Number(formatEther(stats.totalETHDropped || 0n)).toLocaleString(undefined, {
                                  maximumFractionDigits: 4
                              })} ETH
                          </span>
                          <span className="stat-label">ETH Dropped</span>
                      </div>
                      <div className="stat-box" data-tooltip="Total tokens distributed">
                          <span className="stat-value">
                              {Number(formatEther(stats.totalTokensDropped || 0n)).toLocaleString(undefined, {
                                  maximumFractionDigits: 2
                              })}
                          </span>
                          <span className="stat-label">Tokens Dropped</span>
                      </div>
                      <div className="stat-box" data-tooltip="Total NFTs distributed">
                          <span className="stat-value">{Number(stats.totalNFTsDropped || 0).toLocaleString()}</span>
                          <span className="stat-label">NFTs Dropped</span>
                      </div>
                      <div className="stat-box" data-tooltip="Total recipient addresses">
                          <span className="stat-value">{Number(stats.totalRecipients).toLocaleString()}</span>
                          <span className="stat-label">Recipients</span>
                      </div>
                  </div>

                    <div className="drop-box">
                        <div className="mode-tabs">
                            <button 
                                className={`tab ${mode === 'eth' ? 'active' : ''}`}
                                onClick={() => setMode('eth')}
                            >
                                ETH
                            </button>
                            <button 
                                className={`tab ${mode === 'erc20' ? 'active' : ''}`}
                                onClick={() => setMode('erc20')}
                            >
                                ERC20
                            </button>
                            <button 
                                className={`tab ${mode === 'erc721' ? 'active' : ''}`}
                                onClick={() => setMode('erc721')}
                            >
                                ERC721
                            </button>
                        </div>

                        {mode !== 'eth' && (
                            <input
                                type="text"
                                placeholder={`${mode.toUpperCase()} Token Address`}
                                value={tokenAddress}
                                onChange={(e) => setTokenAddress(e.target.value)}
                                className="token-input"
                            />
                        )}

                        <div className="input-section">
                            <div className="file-upload">
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                    id="file-upload"
                                />
                                <label htmlFor="file-upload">
                                    <Upload className="upload-icon" />
                                    <span>Upload CSV</span>
                                </label>
                            </div>

                            <textarea
                                value={walletInput}
                                onChange={handleInputChange}
                                placeholder={`Enter addresses and amounts (one per line):${mode === 'erc721' ? '\n0x123...abc 351' : '\n0x123...abc 1.5\n0x456...def 2.0'}`}
                                className="wallet-input"
                            />

                            {inputSummary.totalWallets > 0 && (
                                <div className="input-summary">
                                    <p>Total Recipients: {inputSummary.totalWallets}</p>
                                    {inputSummary.isNFT ? (
                                        <p>Total NFTs to transfer: {inputSummary.totalAmount}</p>
                                    ) : (
                                        <p>Total Amount: {inputSummary.totalAmount} {mode === 'eth' ? 'ETH' : 'Tokens'}</p>
                                    )}
                                </div>
                            )}
                        </div>

                        {error && <div className="error-message">{error}</div>}

                        <div className="airdrop-button-container">
                            <button 
                                className="airdrop-button"
                                onClick={handleAirdrop}
                                disabled={loading || !walletInput.trim()}
                            >
                                {loading ? 'Processing...' : 'Airdrop Tokens'}
                            </button>
                        </div>
                    </div>
                </>
            ) : (
              <div className="connect-wallet-prompt">
              <h2>Connect Your Wallet</h2>
              <p>Connect your wallet to start dropping tokens like it's hot! 🔥</p>
              <div className="connect-button-wrapper">
                  <ConnectButton />
              </div>
              </div>
            )}

            {showPopup && <EligibilityPopup onClose={() => setShowPopup(false)} />}
        </div>
    );
};

export default KONKDrop;