import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import './KonkLand.css';
import AddToHomeScreenPrompt from './AddToHomeScreenPrompt';
import { checkForUpdates, applyUpdates } from './index';

function KonkLand() {
  const [loading, setLoading] = useState(true);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    checkForUpdates();
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setIsHeaderVisible(false);
      } else {
        setIsHeaderVisible(true);
      }
      setLastScrollTop(st <= 0 ? 0 : st);
    };

    window.addEventListener('scroll', handleScroll);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.update();
      });

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        setUpdateAvailable(true);
      });
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const handleUpdate = () => {
    applyUpdates();
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      });
    }
    window.location.reload();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <>
      {loading && (
        <div className="loading-screen">
          <div className="loading-text">WELCOME TO KONKLAND</div>
        </div>
      )}
      {!loading && (
        <div className="konkland-container" style={backgroundStyle}>
          <header className={`header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
            <div className="header-content">
              <img src="/images/title.png" alt="KONKLAND" className="header-title-image" />
              <div className="header-right">
                <ConnectButton />
                <button className="burger-menu" onClick={toggleMobileMenu}>
                  ☰
                </button>
              </div>
            </div>
          </header>
          {isMobileMenuOpen && (
            <div className="mobile-menu">
              <div className="mobile-menu-content">
                <div className="social-icons">
                  <a href="https://twitter.com/YourTwitterHandle" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://t.me/YourTelegramGroup" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <i className="fab fa-telegram"></i>
                  </a>
                  <a href="https://yourwebsite.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <i className="fas fa-globe"></i>
                  </a>
                </div>
                <ConnectButton />
                <button className="mobile-menu-button" onClick={toggleMobileMenu}>Close Menu</button>
              </div>
            </div>
          )}
          <main className="konkland-main">
          <div className="thumbnails-container">
            <div className="thumbnail-wrapper">
            <a href="https://run.kiyosonk.xyz" target="_blank" rel="noopener noreferrer" className="thumbnail-link">
                <img src="/images/konkrunv1.png" alt="KONK-RUN" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">KONK-RUN</h2>
                  <p className="thumbnail-description">KONK VS GHOST!</p>
                </div>
              </a>
              <a href="https://hypers.kiyosonk.xyz" target="_blank" rel="noopener noreferrer" className="thumbnail-link">
                <img src="/images/hyperscriptions.png" alt="Hyperscriptions" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">Hyperscriptions</h2>
                  <p className="thumbnail-description">Explore the world of Hyperscriptions!</p>
                </div>
              </a>
              <Link to="/konk-hyper-mining" className="thumbnail-link">
                <img src="/images/konkhyperspools.png" alt="KONKED HYPERS POOLS" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">KONKED HYPERS POOLS</h2>
                  <p className="thumbnail-description">Mine HYPERS with KONK</p>
                </div>
              </Link>
              <Link to="/konk-drop" className="thumbnail-link">
                <img src="/images/konkdrop.png" alt="KONK DROP" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">KONK DROP</h2>
                  <p className="thumbnail-description">Airdrop ETH/tokens/Nfts with ease</p>
                </div>
              </Link>
              <div className="thumbnail-link">
                <img src="/images/konkwheel.png" alt="KONK WHEEL" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">KONK WHEEL</h2>
                  <p className="thumbnail-description">Coming Soon</p>
                </div>
              </div>
              <div className="thumbnail-link">
                <img src="/images/konkrunv2.png" alt="KONK RUN V2!" className="thumbnail-image" />
                <div className="thumbnail-info">
                  <h2 className="thumbnail-title">KONK RUN V2!</h2>
                  <p className="thumbnail-description">Coming Soon</p>
                </div>
              </div>
            </div>
          </div>
        </main>
          <AddToHomeScreenPrompt />
          <footer className="footer">
            Made with Konkness
          </footer>
        </div>
      )}
      {updateAvailable && (
        <div className="update-popup">
          <p>A new version is available!</p>
          <button onClick={handleUpdate}>Update Now</button>
        </div>
      )}
    </>
  );
}

export default KonkLand;